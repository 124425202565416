import React from 'react';
import getConfig from 'next/config';
import { JobBoardHero } from '@getro/rombo';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';

import { talentNetworkHeroCtaClicked, claimYourProfileHeroCtaClicked } from '../../../redux/actions/layout';
import {
  claimCompanyProfileInHeroSelector,
  hasAdvancedDesignSelector,
  hideLogoInHeroSelector,
  hideNameInHeroSelector,
  hideNetworkInfoSelector,
  hideNumbersInHeroSelector,
  hideTnCtaInHeroSelector,
  makeFeatureSelector,
  organizationsStringSelector,
  portfolioCompanySelectorFilter,
  talentNetworkSelector,
} from '../../../redux/selectors/network';
import { isAuthenticatedSelector } from '../../../redux/selectors/user';
import { NetworkSchema } from '../../../schema/network';

const { publicRuntimeConfig } = getConfig();
const { getroAppUrl } = publicRuntimeConfig;

export const Hero = ({ network }) => {
  const claimCompanyProfileInHero = claimCompanyProfileInHeroSelector(network);
  const darkHeroText = makeFeatureSelector('dark_hero_text', network);
  const hasAdvancedDesign = hasAdvancedDesignSelector(network);
  const hideNetworkInfo = hideNetworkInfoSelector(network);
  const hideLogoInHero = hideLogoInHeroSelector(network);
  const hideNameInHero = hideNameInHeroSelector(network);
  const hideNumbersInHero = hideNumbersInHeroSelector(network);
  const hideTnCtaInHero = hideTnCtaInHeroSelector(network);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const talentNetwork = talentNetworkSelector(network);
  const organizationsString = organizationsStringSelector(true, network);
  const orgString = portfolioCompanySelectorFilter(network);
  const dispatch = useDispatch();
  const { tagline, subtitle, mainImageUrl, name, tnSettings, heroColorMask, slug } = network;

  return (
    <JobBoardHero
      network={network}
      claimCompanyProfileInHero={claimCompanyProfileInHero}
      darkHeroText={darkHeroText}
      hasAdvancedDesign={hasAdvancedDesign}
      organizationsString={organizationsString}
      orgString={orgString}
      claimYourProfileHeroCtaClicked={() => dispatch(claimYourProfileHeroCtaClicked({ network }))}
      onTalentNetworkHeroCTAClick={() => dispatch(talentNetworkHeroCtaClicked({ network }))}
      getroAppUrl={getroAppUrl}
      hideNetworkInfo={hideNetworkInfo}
      isAuthenticated={isAuthenticated}
      hideLogoInHero={hideLogoInHero}
      hideNameInHero={hideNameInHero}
      hideNumbersInHero={hideNumbersInHero}
      hideTnCtaInHero={hideTnCtaInHero}
      talentNetwork={talentNetwork}
      subtitle={subtitle}
      heroImageUrl={mainImageUrl}
      tagline={tagline}
      tnSettings={tnSettings}
      heroColorMask={heroColorMask}
      slug={slug}
      name={name}
      imageAs={Image}
      bgImageProps={{
        quality: 100,
        loading: 'lazy',
        fill: true,
      }}
    />
  );
};

Hero.propTypes = {
  network: NetworkSchema.isRequired,
};

export default Hero;
