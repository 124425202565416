export const getTabsOrder = (tabsOrderString = '') => {
  const tabs = ['jobs', 'companies', 'talentNetwork'];
  const tabsOrder = {
    jobs: 0,
    companies: 1,
    talentNetwork: 2,
  };

  const tabsOrderArray = tabsOrderString
    .replace('talent_network', 'talentNetwork')
    .split(',')
    .map((s) => s.trim());

  tabs.forEach((e) => {
    const pos = tabsOrderArray.findIndex((t) => t === e);
    if (pos > -1) {
      tabsOrder[e] = pos;
    }
  });

  return tabsOrder;
};
