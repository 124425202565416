import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { NetworkSchema } from '../../../schema/network';

export const CustomHeader = memo(({ network, advancedDesign }) => {
  const customHeaderId = useMemo(() => `${network.label}-custom-header`, [network.label]);
  const customHeaderClassName = `${network.label}-custom-part`;
  if (!advancedDesign.header) return null;

  return (
    <Box
      data-testid="custom-header"
      id={customHeaderId}
      className={customHeaderClassName}
      dangerouslySetInnerHTML={{ __html: advancedDesign.header }}
    />
  );
});

CustomHeader.propTypes = {
  network: NetworkSchema.isRequired,
  advancedDesign: PropTypes.object.isRequired,
};
