import { useEffect, useRef } from 'react';

export const useTriggerScript = () => {
  const ref = useRef(false);

  useEffect(() => {
    if (!ref.current) {
      dispatchEvent(new Event('load-ad-script'));
    }

    ref.current = true;
  }, []);
};
