import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { loadedJobAlertSelector } from '../redux/selectors/jobAlertSelectors';
import JobAlertsAPI from '../api/JobAlertsAPI';
import useFilterParser from './useFilterParser';

const useActiveFilterValues = () => {
  const { filtersToString } = useFilterParser();
  const jobAlert = useSelector(loadedJobAlertSelector);

  const activeAlertFilterValues = useMemo(() => {
    if (!jobAlert.filters) return '';
    const { page, ...otherFilters } = JobAlertsAPI.filtersToQuery(jobAlert);

    return filtersToString(otherFilters);
  }, [jobAlert, filtersToString]);

  return { activeAlertFilterValues };
};

export default useActiveFilterValues;
