import React, { useState, useEffect } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Modal } from '@getro/rombo';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadUnsubscribeJobAlert,
  myJobAlertsCtaClicked,
  resetJobAlertActionsState,
} from '../../../redux/actions/jobAlertActions';
import {
  hasActiveJobAlertSelector,
  loadedJobAlertSelector,
  unsubscribeJobAlertSuccessSelector,
} from '../../../redux/selectors/jobAlertSelectors';
import { makeSelectIsLoading } from '../../../redux/selectors/loading';
import WantsMoreAlertsSurvey from '../wantsMoreAlertsSurvey';
import { NetworkSchema } from '../../../schema/network';
import useActiveFilterValues from '../../../hooks/useActiveFilterValues';
import { CreateJobAlert } from './createJobAlert';

const JobAlertsOnButton = ({ network, ...props }) => {
  const jobAlert = useSelector(loadedJobAlertSelector);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const unsubscribing = useSelector(makeSelectIsLoading('unsubscribeJobAlert'));
  const unsubscribeSuccess = useSelector(unsubscribeJobAlertSuccessSelector);
  const hasActiveJobAlert = useSelector(hasActiveJobAlertSelector);
  const { id: networkId } = network;
  const { activeAlertFilterValues } = useActiveFilterValues();

  useEffect(() => {
    if (unsubscribeSuccess && showModal) {
      setShowModal(false);
      dispatch(resetJobAlertActionsState());
    }
  }, [dispatch, showModal, unsubscribeSuccess]);

  const onTriggerClick = () => {
    dispatch(myJobAlertsCtaClicked({ network, hasJobAlerts: hasActiveJobAlert }));
    setShowModal(true);
  };

  return (
    <Flex textAlign="left" flexDirection="column" {...props}>
      <Flex
        tx="buttons"
        size="small"
        className="theme_only"
        data-testid="my-job-alerts-button"
        mt={2}
        p="8px 16px"
        pr={[0, '16px']}
        variant="tertiary"
        alignSelf="center"
        fontSize="14px"
        sx={{
          borderColor: ['transparent', 'neutral.300'],
        }}
        onClick={onTriggerClick}
      >
        My&nbsp;
        <Box as="span" sx={{ display: ['none', 'block'] }}>
          job&nbsp;
        </Box>
        alerts
      </Flex>
      <Modal
        isOpen={showModal}
        onCancel={() => setShowModal(false)}
        data-testid="my-job-alerts-modal"
        variant="default"
        title="My job alerts"
      >
        {hasActiveJobAlert && (
          <>
            {activeAlertFilterValues && (
              <>
                <Text mb={[4]}>You’ll get daily alerts when new jobs match your preferences:</Text>
                <Text mb={[2]} color="text.dark">
                  {activeAlertFilterValues}
                </Text>
              </>
            )}
            {!activeAlertFilterValues && (
              <Text mb={[2]}>
                You are subscribed to <strong>all jobs</strong> posted by {network.name}.
              </Text>
            )}
            <Flex alignItems="center">
              <Text color="text.subtle" fontSize={[1]}>
                Not interested anymore?
              </Text>
              <Box
                fontWeight="normal"
                variant="tertiary"
                fontSize="14px"
                pl="4px"
                color="text.subtle"
                size="small"
                loading={unsubscribing}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  dispatch(loadUnsubscribeJobAlert({ uuid: jobAlert.id, networkId }));
                }}
              >
                Unsubscribe
              </Box>
              <Text color="text.subtle" fontSize={[1]}>
                .
              </Text>
            </Flex>
            <WantsMoreAlertsSurvey mt={[4]} network={network} />
          </>
        )}
        {!hasActiveJobAlert && <CreateJobAlert network={network} />}
      </Modal>
    </Flex>
  );
};
JobAlertsOnButton.propTypes = { network: NetworkSchema.isRequired };

export default JobAlertsOnButton;
