/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { decryptQuery } from './useEncryptedRouter';
import { sanitizeQuery } from '../helpers/queryString';
import { allCompanySelector } from '../redux/selectors/allCompany';
import { loadedJobAlertSelector } from '../redux/selectors/jobAlertSelectors';
import JobAlertsAPI from '../api/JobAlertsAPI';
import { companyStageFormatter, mapHeadCountToInterval } from '../helpers/stringHelper';

const useFilterParser = () => {
  const router = useRouter();
  const allCompanies = useSelector(allCompanySelector);
  const jobAlert = useSelector(loadedJobAlertSelector);

  const hasFilters = useMemo(() => {
    const { query } = router;

    return 'filter' in query || 'q' in query;
  }, [router]);

  const filters = useMemo(() => {
    const { query } = router;
    const { page, ...otherFilters } = decryptQuery(sanitizeQuery(query));
    return otherFilters;
  }, [router]);

  const filtersToString = useCallback(
    (someFilters) => {
      if (!someFilters) return '';

      const { 'organization.id': organizationIds, ...otherFilters } = someFilters;
      const processedFilters = {
        ...otherFilters,
      };

      if (organizationIds && organizationIds.length && allCompanies.list.length) {
        processedFilters['organization.id'] = organizationIds.map((organizationId) => {
          const company = allCompanies.list.find((c) => c.id === parseInt(organizationId, 10));
          return company.name;
        });
      }

      if (processedFilters['organization.stage']) {
        processedFilters['organization.stage'] = processedFilters['organization.stage'].map(
          (stage) => `${companyStageFormatter(stage)} stage companies`,
        );
      }

      if (Array.isArray(processedFilters.stage)) {
        processedFilters.stage = processedFilters.stage.map(
          (stage) => `${companyStageFormatter(stage)} stage companies`,
        );
      }

      if (processedFilters['organization.head_count']) {
        processedFilters['organization.head_count'] = processedFilters['organization.head_count'].map(
          (headCount) => `${mapHeadCountToInterval(headCount)} employees`,
        );
      }

      if (processedFilters.head_count) {
        processedFilters.head_count = processedFilters.head_count.map(
          (headCount) => `${mapHeadCountToInterval(headCount)} employees`,
        );
      }

      if (processedFilters['organization.headCount']) {
        processedFilters['organization.headCount'] =
          processedFilters['organization.headCount'].map(mapHeadCountToInterval);
      }

      if (processedFilters.headCount) {
        processedFilters.headCount = processedFilters.headCount.map(mapHeadCountToInterval);
      }

      return Object.values(processedFilters)
        .flat()
        .filter((e) => e !== undefined)
        .join(' · ');
    },
    [allCompanies.list],
  );

  const selectedFilters = useMemo(() => filtersToString(filters), [filters, filtersToString]);

  const parsedFilters = useMemo(() => {
    const {
      q: query,
      'organization.id': organizationIds,
      searchable_locations: locations,
      job_functions,
      'organization.industry_tags': organization_industry_tags,
      'organization.head_count': organization_head_count,
      'organization.stage': organization_stage,
      searchable_location_option,
      stage,
      industry_tags,
      head_count,
      ...otherFilters
    } = filters;

    return {
      query,
      organization_ids: organizationIds?.map((organizationId) => parseInt(organizationId, 10)),
      locations,
      job_functions,
      stage: organization_stage || stage,
      industry_tags: organization_industry_tags || industry_tags,
      head_count: organization_head_count || head_count,
      searchable_location_option,
      custom_filters: otherFilters,
    };
  }, [filters]);

  const filtersHaveChanged = useMemo(() => {
    const { filters: activeFilters } = jobAlert;

    const comparableArray = (array) => JSON.stringify(array?.map((e) => e.toString())?.sort());
    const compareFilterSet = (f1, f2) => {
      let result = true;

      const f1Keys = Object.keys(f1).filter((key) => f1[key] !== undefined);
      const f2Keys = Object.keys(f2).filter((key) => f2[key] !== undefined);
      if (comparableArray(f1Keys) !== comparableArray(f2Keys)) {
        return true;
      }

      f1Keys.forEach((filter) => {
        if (Array.isArray(f1[filter])) {
          result = result && comparableArray(f1[filter]) === comparableArray(f2[filter]);
        } else {
          result = result && f1[filter]?.toString() === f2[filter]?.toString();
        }
      });

      return !result;
    };

    const { customFilters: queryCustomFilters, ...otherQueryFilters } = JobAlertsAPI.queryToFilters(filters || {});
    const { customFilters: activeCustomFilters, ...otherActiveFilters } = activeFilters || {};

    return (
      compareFilterSet(otherQueryFilters, otherActiveFilters) ||
      compareFilterSet(queryCustomFilters || {}, activeCustomFilters || {})
    );
  }, [filters, jobAlert]);

  return {
    hasFilters,
    filters,
    parsedFilters,
    selectedFilters,
    filtersToString,
    filtersHaveChanged,
  };
};

export default useFilterParser;
