import React, { useState } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { Button } from '@getro/rombo';
import { useDispatch } from 'react-redux';
import { trackWantsMoreAlertsSurveyClick } from './actions';
import { NetworkSchema } from '../../../schema/network';

const WantsMoreAlertsSurvey = ({ network, ...otherProps }) => {
  const [showResponse, setShowResponse] = useState(false);
  const dispatch = useDispatch();

  const onClick = () => {
    setShowResponse(true);
    dispatch(trackWantsMoreAlertsSurveyClick({ network }));
  };

  return (
    <Flex flexDirection="column" fontSize={[1]} {...otherProps} justifyContent="flex-start" alignItems="flex-start">
      <Button
        variant="tertiary"
        p={0}
        m={0}
        size="small"
        onClick={onClick}
        sx={{ textDecoration: 'underline' }}
        data-testid="wants-another-alert-button"
      >
        Create another alert
      </Button>
      {showResponse && (
        <Text data-testid="survey-response">
          Currently you can only set one alert, we have tracked your interest and will add this option soon.
        </Text>
      )}
    </Flex>
  );
};

WantsMoreAlertsSurvey.propTypes = {
  network: NetworkSchema.isRequired,
};

export default WantsMoreAlertsSurvey;
