import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { NetworkSchema } from '../../../schema/network';

const CustomFooter = ({ network, advancedDesign }) => {
  const customFooterId = useMemo(() => `${network.label}-custom-footer`, [network.label]);
  const customFooterClassName = `${network.label}-custom-part`;

  if (!advancedDesign.footer) return null;

  return (
    <Box
      data-testid="custom-footer"
      id={customFooterId}
      className={customFooterClassName}
      dangerouslySetInnerHTML={{ __html: advancedDesign.footer }}
    />
  );
};

CustomFooter.propTypes = {
  network: NetworkSchema.isRequired,
  advancedDesign: PropTypes.object.isRequired,
};

export default CustomFooter;
