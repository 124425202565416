import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCreateJobAlert } from '../redux/actions/jobAlertActions';
import { makeSelectIsLoading } from '../redux/selectors/loading';
import useFilterParser from './useFilterParser';
import { useJobFunctionsWithoutRequest } from './useJobFunctions';

export const useJobAlerts = (network, source = 'all_notifications') => {
  const dispatch = useDispatch();
  const subscribing = useSelector(makeSelectIsLoading('createJobAlert'));
  const { jobBoardFilters } = network;
  const { jobFunctionFilter } = jobBoardFilters;

  const hideJobFunctionFilter = !jobFunctionFilter;
  const { parsedFilters, selectedFilters } = useFilterParser();
  const { jobFunctions, isLoadingJobFunctions } = useJobFunctionsWithoutRequest({ network });

  const showJobFunctionSelector = useMemo(
    () => !selectedFilters && !hideJobFunctionFilter,
    [selectedFilters, hideJobFunctionFilter],
  );

  const onSubmit = async (values) => {
    const { email, job_function: jobFunction } = values;

    const filters = showJobFunctionSelector
      ? {
          job_functions: [jobFunction?.label],
          custom_filters: {},
        }
      : parsedFilters;

    dispatch(
      loadCreateJobAlert({
        networkId: network.id,
        email,
        silent: source !== 'toggle',
        filters,
        source,
        network,
        selectedFilters,
      }),
    );
  };

  const loading = useMemo(() => {
    if (showJobFunctionSelector) return isLoadingJobFunctions;

    return false;
  }, [isLoadingJobFunctions, showJobFunctionSelector]);

  return {
    loading,
    onSubmit,
    showJobFunctionSelector,
    subscribing,
    jobFunctions,
    selectedFilters,
  };
};
