import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { makeSelectIsLoading } from '../redux/selectors/loading';
import { loadJobFunctions } from '../redux/actions/jobFunctionActions';
import { jobFunctionsSelector } from '../redux/selectors/jobFunctions';

const DEFAULT_JOB_FUNCTIONS = [];

const useJobFunctions = ({ network }) => {
  const dispatch = useDispatch();
  const jobFunctions = useSelector(jobFunctionsSelector);
  const isLoadingJobFunctions = useSelector(makeSelectIsLoading('jobFunctions'));
  const { jobBoardFilters } = network;
  const { jobFunctionFilter } = jobBoardFilters;

  const hideJobFunctionFilter = !jobFunctionFilter;

  useEffect(() => {
    if (!hideJobFunctionFilter && jobFunctions === null && !isLoadingJobFunctions) {
      dispatch(loadJobFunctions({ network }));
    }
  }, [hideJobFunctionFilter, network, jobFunctions, dispatch, isLoadingJobFunctions]);

  return {
    jobFunctions: jobFunctions === null ? DEFAULT_JOB_FUNCTIONS : jobFunctions,
    initialized: jobFunctions !== null,
    isLoadingJobFunctions,
  };
};

export const useJobFunctionsWithoutRequest = () => {
  const jobFunctions = useSelector(jobFunctionsSelector);
  const isLoadingJobFunctions = useSelector(makeSelectIsLoading('jobFunctions'));

  return {
    jobFunctions: jobFunctions === null ? DEFAULT_JOB_FUNCTIONS : jobFunctions,
    initialized: jobFunctions !== null,
    isLoadingJobFunctions,
  };
};

export default useJobFunctions;
