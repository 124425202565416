import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'rebass/styled-components';

const NavbarLink = React.forwardRef(({ children, contracted, alignItems, ...linkProps }, ref) => {
  const { sx = {}, className, ...otherLinkProps } = linkProps;

  const px = useMemo(() => {
    if (contracted) {
      return [2, 3];
    }

    return [3, 3];
  }, [contracted]);

  return (
    <Link
      {...otherLinkProps}
      className={`theme_only ${className}`}
      ref={ref}
      px={px}
      py={[3]}
      fontSize={2}
      color="text.main"
      display="flex"
      alignItems={alignItems}
      sx={{
        position: 'relative',
        textDecoration: 'none',
        transition: 'all ease 0.3s',
        textTransform: ['capitalize', 'capitalize', 'unset'],
        span: {
          display: 'none',
        },
        '&::after': {
          content: "''",
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '0.125rem',
          bg: 'navigationBar',
          opacity: 0,
          transition: 'opacity 0.3s',
        },
        '&:hover': {
          color: 'text.main',
        },
        '&:active': {
          bg: 'neutral.20',
          transform: 'scale(.94)',
        },
        '&.active': {
          fontWeight: 'semibold',
          color: 'text.dark',
          '&::after': {
            opacity: 1,
          },
        },
        ...sx,
      }}
    >
      {children}
    </Link>
  );
});

NavbarLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  contracted: PropTypes.bool,
  alignItems: PropTypes.string,
};

NavbarLink.defaultProps = {
  contracted: false,
  alignItems: 'center',
};

export default NavbarLink;
