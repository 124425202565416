import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, Card, Box, Text } from 'rebass/styled-components';
import { Dropdown, ProfilePicture, Button } from '@getro/rombo';
import { Heart, Edit, LogOut } from 'lucide-react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import Link from '../../atoms/Link';
import NavbarLink from '../../atoms/NavbarLink';
import useUser from '../../../hooks/useUser';
import { loadLogoutRequest } from '../../../redux/actions/userActions';
import { getTabsOrder } from '../../../helpers/tabsOrderHelper';
import useLoginWithRedirectUrl from '../../../hooks/useLoginWithRedirectUrl';
import { showJobAlertsSelector } from '../../../redux/selectors/network';
import JobAlertsOnButton from '../jobAlertsOnButton';
import NumberInNav from '../../layouts/Main/PageLayout/numberInNav';
import { NetworkSchema } from '../../../schema/network';

const Navbar = ({ isAuthenticated, network, talentNetwork, numbersInNav, organizationsString, talentNetworkV2 }) => {
  const { tabsOrder: collectionTabsOrder, jobsCount, companiesCount } = network;
  const userMenuRef = useRef();
  const tabsOrder = getTabsOrder(collectionTabsOrder);
  const router = useRouter();
  const dispatch = useDispatch();
  const [user] = useUser({ network });
  const getTalentNetworkScrollProp = () => !!isAuthenticated;
  const loginWithRedirectUrl = useLoginWithRedirectUrl();
  const showJobAlerts = showJobAlertsSelector(network);
  const navbarLinkContracted = numbersInNav && (talentNetwork || showJobAlerts);

  const showTalentNetworkv1 = talentNetwork && !talentNetworkV2;

  return (
    <Flex data-testid="navbar" flexDirection="row" width={[1]} bg="white" sx={{ boxShadow: '0 1px 0 0 #e5e5e5' }}>
      <Flex width={[1, 1, '1100px']} mx="auto" sx={{ maxWidth: '100%' }}>
        <Link href="/jobs" scroll={false} exact passHref>
          <NavbarLink alignItems="baseline" sx={{ order: tabsOrder.jobs }} contracted={navbarLinkContracted}>
            <Text display={['none', 'none', 'inline-block']}>Search&nbsp;</Text>
            jobs
            {numbersInNav && <NumberInNav number={jobsCount} />}
          </NavbarLink>
        </Link>
        <Link
          href="/companies"
          scroll={false}
          passHref
          match={({ href }) => {
            const currentPath = href.split('#')[0];
            if (router.pathname.toLowerCase() === currentPath) {
              return true;
            }
            return (
              router.pathname.toLowerCase().indexOf(currentPath) === 0 &&
              Object.prototype.hasOwnProperty.call(router.query, 'companySlug') &&
              !Object.prototype.hasOwnProperty.call(router.query, 'jobSlug')
            );
          }}
        >
          <NavbarLink alignItems="baseline" sx={{ order: tabsOrder.companies }} contracted={navbarLinkContracted}>
            <Text display={['none', 'none', 'inline-block']}>Explore&nbsp;</Text>
            {organizationsString}
            {numbersInNav && <NumberInNav number={companiesCount} />}
          </NavbarLink>
        </Link>
        {(talentNetwork || talentNetworkV2) && (
          <>
            <Link href="/talent-network" scroll={getTalentNetworkScrollProp()} exact passHref>
              <NavbarLink
                sx={{ order: tabsOrder.talentNetwork, textTransform: 'unset' }}
                contracted={navbarLinkContracted}
              >
                Talent<Text display={['none', 'none', 'inline-block']}>&nbsp;network</Text>
              </NavbarLink>
            </Link>
            {!isAuthenticated && showTalentNetworkv1 && (
              <NextLink shallow passHref href={loginWithRedirectUrl} scroll={false} legacyBehavior>
                <Button
                  sx={{
                    marginLeft: 'auto',
                    marginRight: [3, 3, 0],
                  }}
                  as="a"
                  className="theme_only"
                  alignSelf="center"
                  order="9999"
                  href={loginWithRedirectUrl}
                  variant="secondary"
                  size="small"
                  fontSize={1}
                >
                  Log in
                </Button>
              </NextLink>
            )}
            {isAuthenticated && showTalentNetworkv1 && (
              <Box
                data-testid="authenticated"
                alignSelf="center"
                order="9999"
                sx={{
                  marginLeft: 'auto',
                  marginRight: [3, 3, 0],
                }}
              >
                <Dropdown
                  placement="bottom-end"
                  ref={userMenuRef}
                  variant="shadow"
                  triggerProps={{
                    variant: 'styleless',
                    sx: { width: '36px', opacity: 0.8, cursor: 'pointer', ':hover': { opacity: 1 } },
                    children: <ProfilePicture imageUrl={user.avatarUrl} name={`${user.firstName} ${user.lastName}`} />,
                  }}
                >
                  <Card sx={{ boxShadow: 'unset' }} variant="default" p={0}>
                    <Flex as="ul" sx={{ p: 0, listStyle: 'none' }} flexDirection="column" width="200px;">
                      <Box as="li">
                        <NextLink passHref href="/jobs/favorited#content" scroll={false} legacyBehavior>
                          <Button
                            width={1}
                            onClick={() => userMenuRef.current.closeDropdown()}
                            display="flex"
                            alignItems="center"
                            color="text.main"
                            fontSize={2}
                            as="a"
                            className="theme_only"
                            variant="sideBarNav"
                            href="/jobs/favorited"
                          >
                            <Box
                              variant="icon"
                              className="fa fa-heart-o"
                              height="14px"
                              width="14px"
                              strokeWidth="2"
                              aria-hidden="true"
                              as={Heart}
                              mr={2}
                            />
                            My jobs
                          </Button>
                        </NextLink>
                      </Box>
                      <Box as="li">
                        <NextLink shallow passHref href="/companies/favorited" scroll={false} legacyBehavior>
                          <Button
                            width={1}
                            onClick={() => userMenuRef.current.closeDropdown()}
                            display="flex"
                            alignItems="center"
                            color="text.main"
                            fontSize={2}
                            as="a"
                            className="theme_only"
                            variant="sideBarNav"
                            href="/companies/favorited"
                          >
                            <Box
                              variant="icon"
                              className="fa fa-heart-o"
                              height="14px"
                              width="14px"
                              strokeWidth="2"
                              aria-hidden="true"
                              as={Heart}
                              mr={2}
                            />
                            My {organizationsString}
                          </Button>
                        </NextLink>
                      </Box>
                      <Box as="li">
                        <NextLink shallow passHref href="/extended-profile" scroll={false} legacyBehavior>
                          <Button
                            width={1}
                            onClick={() => userMenuRef.current.closeDropdown()}
                            display="flex"
                            alignItems="center"
                            color="text.main"
                            fontSize={2}
                            as="a"
                            className="theme_only"
                            variant="sideBarNav"
                            href="/extended-profile"
                          >
                            <Box
                              variant="icon"
                              className="fa fa-edit-o"
                              height="14px"
                              width="14px"
                              strokeWidth="2"
                              aria-hidden="true"
                              as={Edit}
                              mr={2}
                            />
                            My profile
                          </Button>
                        </NextLink>
                      </Box>
                      <Box as="li">
                        <Button
                          width={1}
                          display="flex"
                          alignItems="center"
                          color="text.main"
                          fontSize={2}
                          variant="sideBarNav"
                          onClick={() => {
                            dispatch(loadLogoutRequest({ network }));
                            userMenuRef.current.closeDropdown();
                          }}
                        >
                          <Box
                            variant="icon"
                            className="fa fa-sign-out"
                            height="14px"
                            width="14px"
                            strokeWidth="2"
                            aria-hidden="true"
                            as={LogOut}
                            mr={2}
                          />
                          Logout
                        </Button>
                      </Box>
                    </Flex>
                  </Card>
                </Dropdown>
              </Box>
            )}
          </>
        )}
        {showJobAlerts && (
          <JobAlertsOnButton
            order="9999"
            network={network}
            sx={{
              marginLeft: 'auto',
              marginRight: [3, 3, 0],
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  network: NetworkSchema.isRequired,
  talentNetwork: PropTypes.bool.isRequired,
  numbersInNav: PropTypes.bool.isRequired,
  organizationsString: PropTypes.string.isRequired,
  talentNetworkV2: PropTypes.bool.isRequired,
};
export default Navbar;
