export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const companyStageFormatter = (filter) => {
  switch (filter) {
    case 'pre_seed':
      return 'Pre Seed';
    case 'seed':
      return 'Seed';
    case 'series_a':
      return 'Series A';
    case 'series_b':
      return 'Series B';
    case 'series_c':
      return 'Series C';
    case 'series_c_plus':
      return 'Series C+';
    case 'private_equity':
      return 'Private Equity';
    case 'ipo':
      return 'IPO';
    case 'ico':
      return 'ICO';
    default:
      return 'Other';
  }
};

export const mapHeadCountToInterval = (data) => {
  const value = parseInt(data, 10);

  switch (value) {
    case 1:
      return '1 - 10';
    case 2:
      return '11 - 50';
    case 3:
      return '51 - 200';
    case 4:
      return '201 - 1000';
    case 5:
      return '1001 - 5000';
    default:
      return '5001+';
  }
};
