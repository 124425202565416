import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import { Button, FormFieldError, Select, Input } from '@getro/rombo';
import * as Yup from 'yup';

const CreateJobAlertPanel = ({
  onSubmit,
  showJobFunctions,
  subscribing,
  jobFunctions,
  header,
  footer,
  title,
  description,
  sx,
  buttonText,
  inModal,
}) => {
  const formSchema = useMemo(() => {
    if (showJobFunctions) {
      return Yup.object().shape({
        email: Yup.string().email().required(),
        job_function: Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .nullable(),
      });
    }

    return Yup.object().shape({
      email: Yup.string().email().required(),
    });
  }, [showJobFunctions]);

  return (
    <Formik initialValues={{ email: '', job_function: null }} validationSchema={formSchema} onSubmit={onSubmit}>
      {({ values, touched, errors, setFieldValue, isSubmitting, handleSubmit }) => (
        <Form
          style={{ width: '100%' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        >
          <Flex flexDirection="column" alignItems="center" sx={{ px: [0, 4, 5], py: [0], ...sx }}>
            {header || (
              <>
                <Text fontWeight="semibold" fontSize={[4]} mb={[2]} color="text.dark" textAlign="center">
                  {title}
                </Text>
                <Text color="text.main" fontSize={[1]} fontWeight="body" mb={[3]}>
                  {description}
                </Text>
              </>
            )}
            <Flex flexDirection={['column', 'row']} width="100%" mt={[2]} justifyContent="center">
              <Flex sx={{ flexGrow: 2 }} flexDirection="column" maxWidth={['100%', inModal ? '100%' : '280px']}>
                <Field
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Your email"
                  sx={{ flexGrow: 2, height: '40px' }}
                  component={Input}
                  value={values.email}
                  size="large"
                  onChange={(e) => setFieldValue('email', e.target.value)}
                />
                {touched.email && errors.email && <FormFieldError error={errors.email} />}
              </Flex>
              {showJobFunctions && (
                <Flex
                  flexDirection="column"
                  flexGrow="2"
                  ml={[0, 2]}
                  mt={[2, 0]}
                  w={['100%', '200px']}
                  minWidth={['100%', '200px']}
                  maxWidth={['100%', '200px']}
                  sx={{ '.Select__menu': { zIndex: 3 }, height: '40px' }}
                >
                  <Field
                    name="job_function"
                    id="job_function"
                    component={(props) => (
                      <Select
                        placeholder="Job function"
                        name="job_function"
                        {...props}
                        value={values.job_function}
                        options={jobFunctions}
                        onChange={(e) => setFieldValue('job_function', e)}
                        anchorToBody
                        size="large"
                      />
                    )}
                  />
                  {touched.job_function && errors.job_function && <FormFieldError error="Job function is required" />}
                </Flex>
              )}
              <Button
                type="submit"
                variant="primary"
                ml={[0, 2]}
                mt={[2, 0]}
                height="40px"
                size="large"
                loading={subscribing || isSubmitting}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {buttonText}
              </Button>
            </Flex>
            {footer || null}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

CreateJobAlertPanel.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showJobFunctions: PropTypes.bool.isRequired,
  jobFunctions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  subscribing: PropTypes.bool,
  footer: PropTypes.node,
  header: PropTypes.node,
  sx: PropTypes.object,
  buttonText: PropTypes.string,
  inModal: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
};

CreateJobAlertPanel.defaultProps = {
  subscribing: false,
  jobFunctions: [],
  footer: null,
  header: null,
  sx: {},
  buttonText: 'Get alerts',
  inModal: false,
  title: 'Be the first to know about new jobs',
};

export default CreateJobAlertPanel;
