import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllCompanies } from '../redux/actions/companies';
import { makeSelectIsLoading } from '../redux/selectors/loading';
import { allCompanySelector } from '../redux/selectors/allCompany';

const useAllCompanies = ({ network = {} }) => {
  const { id } = network || {};
  const allCompanies = useSelector(allCompanySelector);
  const isLoading = useSelector(makeSelectIsLoading('allCompanies'));
  const { list, error, initialized } = allCompanies;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialized && !isLoading && id) {
      dispatch(loadAllCompanies({ network }));
    }
  }, [network, dispatch, isLoading, initialized, id]);

  return { list, error, initialized, isLoading };
};

export const useAllCompaniesWithoutRequest = () => {
  const allCompanies = useSelector(allCompanySelector);
  const isLoading = useSelector(makeSelectIsLoading('allCompanies'));
  const { list, error, initialized } = allCompanies;

  return { list, error, initialized, isLoading };
};

export default useAllCompanies;
