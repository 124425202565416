import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass/styled-components';

const NumberInNav = ({ number }) => (
  <Text fontSize={[1]} color="text.subtle" fontWeight="medium">
    {' '}
    &nbsp;{number.toLocaleString('en-US')}
  </Text>
);

NumberInNav.propTypes = {
  number: PropTypes.number.isRequired,
};

export default NumberInNav;
